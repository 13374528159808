import React from "react"
import Layout from "../components/layout"
import { Container } from 'react-bootstrap/'
import SEO from "../components/seo"

import { graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import TopHeader from "../components/top-header"
// import Search from "../components/search"
export default class Rehber extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggleOn: true,
      UrlQuery: "",
    }
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }
  componentDidMount() {
    this.setState({ UrlQuery: window.location.pathname })
  }
  render() {
    const { data } = this.props
    const { UrlQuery } = this.state

    return (<Layout>
      <TopHeader />
      <SEO
        title='RetmesRehber'
        description='' />
      <Container style={{ textAlign: '-webkit-center', marginTop: '5%', paddingBottom: '2rem' }}>

        <h1><FormattedMessage id="media_retmes_h1" /><b><FormattedMessage id="retmes_guide" />
        </b>
        </h1>

        {/* <input className='searchbox' type="text" placeholder='Ara...' onChange={this.filterList} />
        {data.allLettersJson.edges.map(({ node }) => (
          <div className='guide-box-dict' key={node.id}>
            <h3 className='guide-box-title'>{node.word}</h3>
            <p className='guide-box-desc'>{node.desc}</p>
          </div>

        ))}
  <div></div> */}
        {UrlQuery.includes("/en") === false ?
          data.allLettersJson.edges.map(({ node }) => (
            <div className='guide-box-dict' key={node.item}>
              <h2 className='guide-box-title'>{node.word}</h2>
              <p className='guide-box-desc'>{node.desc}</p>
            </div>
          ))
          :
          data.allLettersengJson.edges.map(({ node }) => (
            <div className='guide-box-dict' key={node.item}>
              <h2 className='guide-box-title'>{node.word}</h2>
              <p className='guide-box-desc'>{node.desc}</p>
            </div>
          ))
        }
      </Container>
    </Layout >
    )
  }
}

export const pageQuery = graphql`
query{
  allLettersJson(sort: {order: ASC, fields: word}) {
    edges {
      node {
        id
        desc
        word
      }
    }
    totalCount
  }
  allLettersengJson {
    edges {
      node {
        id
        word
        desc
      }
    }
  }
}


`